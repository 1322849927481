.info,
.info-text,
.footer,
.clipboard-message,
.title,
.controls,
.credits {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
}
.info,
.footer,
.clipboard-message {
  font-family: Courier;
  font-size: 2.2vh;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1px 3px #000;
}
.info,
.clipboard-message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.info-layout .info-text {
  width: 100%;
  height: 26vh;
  font-size: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 6vh;
  max-width: 42vh;
  margin: auto;
}
.info-layout::before {
  content: '';
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 24vh solid #000;
  border-right: none;
  border-left: none;
  box-sizing: border-box;
}
.info-rotation,
.info-colors,
.clipboard-message {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 5vh;
}
.info-rotation .info-text,
.info-colors .info-text,
.clipboard-message .info-text {
  position: relative;
}
.info-rotation::before,
.info-colors::before,
.clipboard-message::before {
  content: '';
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 5vh solid #fff;
}
.info-colors {
  display: flex;
  align-items: center;
  width: 36vh;
  height: 26vh;
  margin: 7vh;
  padding: 2vh;
  box-sizing: border-box;
}
.info-colors::before {
  background: #fff;
}
.clipboard-message {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.clipboard-message .info-text {
  font-size: 2.6vh;
}
.clipboard-message::before {
  background: #fff;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20vh;
  padding-top: 4vh;
  text-align: center;
}
.footer .title {
  font-size: 3vh;
  text-transform: uppercase;
}
.footer .title a {
  color: #fff;
}
.footer .controls {
  cursor: pointer;
  font-size: 2.6vh;
  white-space: nowrap;
  margin: 3vh 0 3.7vh;
}
.footer .controls span {
  text-decoration: underline;
}
.footer .controls span:active {
  color: #000;
}
.footer::before {
  background: linear-gradient(transparent, #000);
  content: '';
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
